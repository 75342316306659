<template>
    <div class="general_right" >
      <div class="generalTabs">
        <el-tabs v-model="activeName" @tab-click="clickTabs">
          <el-tab-pane label="交易金额(元)" name="goodOrderAmountChart"></el-tab-pane>
          <el-tab-pane label="进账金额(元)" name="serviceOrderAmountChart"></el-tab-pane>
          <el-tab-pane label="服务单数(单)" name="serviceOrderCountChart"></el-tab-pane>
          <el-tab-pane label="订单总数(单)" name="goodOrderCountChart"></el-tab-pane>
        </el-tabs>

        <div class="tabs_day">
          <div class="time">
            <el-date-picker
                v-model="times"
                type="daterange"
                size="mini"
                @change="changeTime"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
                :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>

          <el-dropdown  @command="handleCommand">
          <span class="el-dropdown-link">
            {{dayStr}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="item"
                                v-for="item in dayList"
                                :key="item.type"
                                :command="item.type"
              >{{item.text}}</el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

<!--      <div class="goodOrderAmountChart" v-if="activeName==='goodOrderAmountChart'">-->
        <div v-loading="loading" v-if="activeName==='goodOrderAmountChart'" id="goodOrderAmountChart"></div>
<!--      </div>-->

<!--      <div class="serviceOrderAmountChart" v-if="activeName==='serviceOrderAmountChart'">-->
        <div v-loading="loading" v-if="activeName==='serviceOrderAmountChart'" id="serviceOrderAmountChart"></div>
<!--      </div>-->

<!--      <div class="serviceOrderCountChart" v-if="activeName==='serviceOrderCountChart'">-->
        <div v-loading="loading" v-if="activeName==='serviceOrderCountChart'" id="serviceOrderCountChart"></div>
<!--      </div>-->

<!--      <div class="goodOrderCountChart" v-if="activeName==='goodOrderCountChart'">-->
        <div v-loading="loading" v-if="activeName==='goodOrderCountChart'" id="goodOrderCountChart"></div>
<!--      </div>-->

    </div>
</template>


<script>
import { Column } from '@antv/g2plot';
import dayjs from 'dayjs'
export default {
  data(){
    return {
      loading:true,
      activeName: 'goodOrderAmountChart',
      data: [],
      searchForm:{
        startTime: dayjs().add(-6,'day').format('YYYY-MM-DD'),
        endTime: dayjs().format('YYYY-MM-DD'),
      },
      dayList: [
        {type: 1, text: "今日"},
        {type: 2, text: "昨天"},
        {type: 3, text: "近7天"},
        {type: 4, text: "近1个月"},
      ],
      dayStr:'近7天',
      goodOrderAmountCharts:null,
      serviceOrderAmountCharts:null,
      serviceOrderCountCharts:null,
      goodOrderCountCharts:null,
      times:'',
      choiceDate: null,
      pickerOptions: {
        disabledDate:(time) => {
          // 如何选择了一个日期
          if (this.choiceDate) {
            const one =  30 * 24 * 3600 * 1000
            const minTime = this.choiceDate - one
            const maxTime = this.choiceDate + one
            return (
                time.getTime() < minTime ||
                time.getTime() > maxTime ||
                // 限制不能选择今天及以后
                time.getTime() > Date.now()
            )
          }else{
            // 如果没有选择日期，就要限制不能选择今天及以后
            return time.getTime() > Date.now() - 8.64e6
          }
        },
        onPick: ({ maxDate, minDate }) => {
          // 把选择的第一个日期赋值给一个变量。
          this.choiceDate = minDate.getTime()
          // 如何你选择了两个日期了，就把那个变量置空
          if (maxDate) this.choiceDate = ''
        },

      },
    }
  },
  created() {
    this.times = [this.searchForm.startTime,this.searchForm.endTime]
    this.handleCommand(3)
  },
  methods:{
    handleCommand(command) {
      let day = this.dayList.find((item) => item.type === command)
      this.dayStr = day.text
      if(command===1){
        this.searchForm.startTime = dayjs().format('YYYY-MM-DD')
        this.searchForm.endTime = dayjs().format('YYYY-MM-DD')
      }else if(command===2){
        this.searchForm.startTime = dayjs().add(-1,'day').format('YYYY-MM-DD')
        this.searchForm.endTime = dayjs().add(-1,'day').format('YYYY-MM-DD')
      }else if(command===3){
        this.searchForm.startTime = dayjs().add(-6,'day').format('YYYY-MM-DD')
        this.searchForm.endTime = dayjs().format('YYYY-MM-DD')
      }else if(command===4){
        this.searchForm.startTime = dayjs().add(-1,'month').format('YYYY-MM-DD')
        this.searchForm.endTime = dayjs().format('YYYY-MM-DD')
      }
      this.times = [this.searchForm.startTime, this.searchForm.endTime]
      this.choiceDate = this.searchForm.startTime
      this.clickTabs()
    },
    clickTabs(){
      this.loading = true
      if(this.activeName === 'goodOrderAmountChart'){
        this.getGoodOrderAmountChartData()
      }
      if(this.activeName === 'serviceOrderAmountChart'){
        this.getServiceOrderAmountChartData()
      }
      if(this.activeName  === 'serviceOrderCountChart'){
        this.getServiceOrderCountChartData()
      }
      if(this.activeName  === 'goodOrderCountChart'){
        this.getGoodOrderCountChartData()
      }
      this.hanleDestroy()
    },
    handleTabs(event){
      this.dayIndex = event.target.attributes['data-index'].value
    },
    changeTime(){
      const [startTime,endTime] = this.times
      this.searchForm.startTime = startTime
      this.searchForm.endTime = endTime
      this.clickTabs()
    },
    async getGoodOrderAmountChartData(){
      this.$curl.post('/hm/homePage/goodOrderAmountChart',this.searchForm).then(res=>{
        this.data = res.data
        this.goodOrderAmountChart()
      })
    },
    goodOrderAmountChart() {
      if(this.goodOrderAmountCharts){
       this.goodOrderAmountCharts.destroy()
        this.goodOrderAmountCharts = null
      }
      this.goodOrderAmountCharts = new Column('goodOrderAmountChart', {
        data: this.data,
        xField: 'key',
        yField: 'value',
        color: '#FD6458',
        maxColumnWidth:30,
        label: {
          // 可手动配置 label 数据标签位置
          position: 'middle', // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: '#FFFFFF',
            opacity: 1,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
            style:{
              fill: '#000000'
            }
          },
          line: {
            style: {
              lineWidth: 2,
              lineDash: false,
            }
          }
        },
        yAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
            style:{
              fill: '#000000'
            }
          },
          grid: {
            line: {
              style: {
                lineWidth: 2,
                lineDash: [5, 5],
              }
            }
          }
        },
        meta: {
          key: {
            alias: '日期',
          },
          value: {
            alias: '交易金额(元)',
          },
        },
      });
      this.goodOrderAmountCharts.render()
      this.loading = false
    },

    async getServiceOrderAmountChartData(){
      this.$curl.post('/hm/homePage/serviceOrderAmountChart',this.searchForm).then(res=>{
        this.data = res.data
        this.serviceOrderAmountChart()
      })
    },
    serviceOrderAmountChart() {
      if(this.serviceOrderAmountCharts){
        this.serviceOrderAmountCharts.destroy()
        this.serviceOrderAmountCharts = null
      }
      this.serviceOrderAmountCharts = new Column('serviceOrderAmountChart', {
        data: this.data,
        xField: 'key',
        yField: 'value',
        color: '#1890FF',
        maxColumnWidth:30,
        label: {
          // 可手动配置 label 数据标签位置
          position: 'middle', // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: '#FFFFFF',
            opacity: 1,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
            style:{
              fill: '#000000'
            }
          },
          line: {
            style: {
              lineWidth: 2,
              lineDash: false,
            }
          }
        },
        yAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
            style:{
              fill: '#000000'
            }
          },
          grid: {
            line: {
              style: {
                lineWidth: 2,
                lineDash: [5, 5],
              }
            }
          }
        },
        meta: {
          key: {
            alias: '日期',
          },
          value: {
            alias: '进账金额(元)',
          },
        },
      });
      this.serviceOrderAmountCharts.render()
      this.loading = false

    },

    async getServiceOrderCountChartData(){
      this.$curl.post('/hm/homePage/serviceOrderCountChart',this.searchForm).then(res=>{
        this.data = res.data
        this.serviceOrderCountChart()
      })
    },
    serviceOrderCountChart() {
      if(this.serviceOrderCountCharts){
        this.serviceOrderCountCharts.destroy()
        this.serviceOrderCountCharts = null
      }
      this.serviceOrderCountCharts = new Column('serviceOrderCountChart', {
        data: this.data,
        xField: 'key',
        yField: 'value',
        color: '#F1A739',
        maxColumnWidth:30,
        label: {
          // 可手动配置 label 数据标签位置
          position: 'middle', // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: '#FFFFFF',
            opacity: 1,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
            style:{
              fill: '#000000'
            }
          },
          line: {
            style: {
              lineWidth: 2,
              lineDash: false,
            }
          }
        },
        yAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
            style:{
              fill: '#000000'
            }
          },
          grid: {
            line: {
              style: {
                lineWidth: 2,
                lineDash: [5, 5],
              }
            }
          }
        },
        meta: {
          key: {
            alias: '日期',
          },
          value: {
            alias: '服务单数(单)',
          },
        },
      });
      this.serviceOrderCountCharts.render()
      this.loading = false

    },

    async getGoodOrderCountChartData(){
      this.$curl.post('/hm/homePage/goodOrderCountChart',this.searchForm).then(res=>{
        this.data = res.data
        this.goodOrderCountChart()
      })
    },
    goodOrderCountChart() {
      if(this.goodOrderCountCharts){
        this.goodOrderCountCharts.destroy()
        this.goodOrderCountCharts = null
      }
      this.goodOrderCountCharts = new Column('goodOrderCountChart', {
        data: this.data,
        xField: 'key',
        yField: 'value',
        color: '#3D7FFF',
        maxColumnWidth:30,
        label: {
          // 可手动配置 label 数据标签位置
          position: 'middle', // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: '#FFFFFF',
            opacity: 1,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
            style:{
              fill: '#000000'
            }
          },
          line: {
            style: {
              lineWidth: 2,
              lineDash: false,
            }
          }
        },
        yAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
            style:{
              fill: '#000000'
            }
          },
          grid: {
            line: {
              style: {
                lineWidth: 2,
                lineDash: [5, 5],
              }
            }
          }
        },
        meta: {
          key: {
            alias: '日期',
          },
          value: {
            alias: '订单总数(单)',
          },
        },
      });
      this.goodOrderCountCharts.render()
      this.loading = false

    },

    hanleDestroy(){
      if(this.goodOrderCountCharts && this.activeName!=='goodOrderCountChart'){
        this.goodOrderCountCharts.destroy()
        this.goodOrderCountCharts = null
      }
      if(this.serviceOrderAmountCharts && this.activeName!=='serviceOrderAmountChart'){
        this.serviceOrderAmountCharts.destroy()
        this.serviceOrderAmountCharts = null
      }
      if(this.goodOrderAmountCharts && this.activeName!=='goodOrderAmountChart'){
        this.goodOrderAmountCharts.destroy()
        this.goodOrderAmountCharts = null
      }
      if(this.serviceOrderCountCharts && this.activeName!=='serviceOrderCountChart'){
        this.serviceOrderCountCharts.destroy()
        this.serviceOrderCountCharts = null
      }
    }
  },
}
</script>


<style scoped lang="scss">
.general_right {
  padding: 0px 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.rank-list {
  flex: 1;
  margin-left: 20px;
}
.generalTabs{
  position: relative;
  display: flex;
  justify-content: space-between;
  .time{
    height: 40px;
    line-height: 40px;
    min-width: 200px;
  }
  .tabs_day{
    display: flex;
    align-items: baseline;
    .time{
      margin-right: 20px;
    }
  }

  ::v-deep .el-tabs__item{
    color: #666666 !important;
    &:hover {
      color: #3e7eff !important;
    }
    &:active {
      color: #3e7eff !important;
    }
    &.is-active {
      color: #3e7eff !important;
    }
  }
}
//.goodOrderCountChart,
//.goodOrderAmountChart,
//.serviceOrderCountChart,
//.serviceOrderAmountChart{
//  margin-bottom: 10px;
//  flex: 1;
//}
#goodOrderCountChart,
#goodOrderAmountChart,
#serviceOrderCountChart,
#serviceOrderAmountChart{
  margin-bottom: 10px;
  flex: 1;
}

::v-deep .el-range-separator{
  width: fit-content !important;
}
::v-deep .el-range__close-icon{
  width: 0 !important;
}
.el-dropdown-menu__item {
  padding: 0 18px 0 10px;
}
@media screen  and (max-width: 1680px) {
  ::v-deep .el-tabs__item {
    padding: 0 10px
  }
  ::v-deep  .el-range-editor.el-input__inner {
    width: 200px !important;
  }
}
@media (max-width: 1200px) {
  .generalTabs{
    display: block;
    width: 500px;
  }
}
</style>